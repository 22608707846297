<template>
  <div class="max-w-[96rem] mx-auto">
    <st-title
      :navItems="[
        {
          name: $t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_breadcrumb1'),
          url: 'projects'
        },
        {
          name: $t('studio.prj_prod_mngmt.prj_reg.breadcrumb2')
        }
      ]"
      :title="$t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_title')"
    />
    <div class="flex gap-24 mt-40">
      <div class="flex-1">
        <st-box>
          <h3
            class="text-4xl font-bold leading-3xl text-on-surface-elevation-2 text-wrap break-all"
          >
            {{ projectName }}
          </h3>
          <st-form-title
            :formTitle="$t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_name')"
            class="mt-24"
            required
          />
          <input-text
            name="productOriginal.productName"
            :placeholder="$t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_name_place_holder')"
            maxLength="50"
            :rules="{
              required: {
                value: true,
                message: $t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_name_val_msg1')
              },
              max_length: 50,
              regex: {
                regex: REGEX_PRODUCT_NAME,
                message: $t('studio.prj_prod.this_prod.home_product_setting_basic_msg4_invalid')
              }
            }"
          />
          <st-form-title
            :formTitle="$t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_type')"
            class="mt-24"
            required
          />
          <p class="text-xs leading-xs text-placeholder">
            {{ $t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_type_guide') }}
          </p>
          <div class="relative mt-4">
            <dropdown
              name="productOriginal.productType"
              :options="items"
              :dropdownProps="{
                size: 'lg',
                variant: 'line',
                distance: 4,
                offset: [0, 0],
                placement: 'bottom',
                class: 'w-full'
              }"
              closeOnClick
              dropdownBtnClass="w-full"
              :rules="{
                required: {
                  value: true,
                  message: $t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_type_val_msg')
                }
              }"
              :placeholder="$t('studio.prj_prod_mngmt.post_prj_reg.dropbox_prod_reg_type')"
            />
          </div>

          <div class="flex items-center gap-16 mt-24">
            <st-form-title
              :formTitle="$t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.title')"
              class="mt-4"
            />
            <s-switch
              id="chk-02"
              v-model="registerBulk"
              size="md"
              @update:modelValue="handleChangeBulk"
            />
          </div>
          <div v-show="registerBulk">
            <st-form-title
              :formTitle="$t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.prod_num')"
              class="mt-24"
              required
            />
            <p class="text-xs leading-xs text-placeholder">
              {{ $t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.guide1') }}
            </p>
            <p class="mt-4 text-xs leading-xs text-brand-primary">
              {{ $t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.guide2') }}
            </p>
            <div class="mt-4">
              <input-text
                name="numberOfProducts"
                :placeholder="
                  $t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.prod_num_input_place_holder')
                "
                :rules="productBulkRules"
              />
            </div>
          </div>
        </st-box>
        <div class="mt-40 flex justify-center gap-16">
          <s-button
            variant="primary"
            size="lg"
            class="!min-w-160"
            :isDisabled="isDisabled"
            @click="onSubmit"
          >
            {{ $t('studio.prj_prod_mngmt.post_prj_reg.btn_prod_reg_cf') }}
          </s-button>
        </div>
      </div>
      <right-wing>
        <right-wing-item
          :rightWingTitle="$t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_guide1')"
          numberValue="01"
        >
          <safe-html as="span" :html="$t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_guide2')" />
        </right-wing-item>
        <right-wing-item
          :rightWingTitle="$t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_type_guide1')"
          numberValue="02"
          class="mt-24"
        >
          {{ $t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_type_guide2') }}
        </right-wing-item>
        <ul class="mt-4 text-sm leading-md text-on-surface-elevation-3">
          <li
            class="relative pl-16 before:absolute before:top-[.9rem] before:left-4 before:w-4 before:h-4 before:rounded-full before:bg-[currentColor]"
          >
            <strong class="font-medium text-on-surface-elevation-2">Game</strong>
            - {{ $t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_type_guide3') }}
          </li>
          <!-- <li
            class="relative pl-16 before:absolute before:top-[.9rem] before:left-4 before:w-4 before:h-4 before:rounded-full before:bg-[currentColor]"
          >
            <strong class="font-medium text-on-surface-elevation-2">Content</strong>
            - {{ $t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_type_guide4') }}
          </li> -->
          <li
            class="relative pl-16 before:absolute before:top-[.9rem] before:left-4 before:w-4 before:h-4 before:rounded-full before:bg-[currentColor]"
          >
            <strong class="font-medium text-on-surface-elevation-2">Utility</strong>
            - {{ $t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_type_guide5') }}
          </li>
        </ul>
        <p class="mt-8 text-sm leading-md text-on-surface-elevation-3">
          {{ $t('studio.prj_prod_mngmt.post_prj_reg.prod_reg_type_guide6') }}
        </p>
      </right-wing>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm, useSetFieldError, useSetFieldValue } from 'vee-validate';
import { computed, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave, useRoute } from 'vue-router';

import {
  checkProductInProjectNameApi,
  checkRegisterProductInProjectBulkApi,
  createProductBulkInProjectApi,
  createProductInProjectApi
} from '@/apis/project-product.api';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import StTitle from '@/components/common/st-title.vue';
import AddProductMassDialog from '@/components/project-product/add-product-mass-dialog.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import InputText from '@/components/validation/input-text.vue';
import { useApp } from '@/composables/useApp';
import { showDialog } from '@/composables/useDialog';
import { STATUS_CODE } from '@/constants/error.const';
import { PRODUCT_DETAIL_TYPE } from '@/constants/products.const';
import { PRODUCT_TYPES } from '@/constants/project-product.const';
import { REGEX_PRODUCT_NAME } from '@/constants/regex.const';
import { PROJECT_MNG_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { RuleNames } from '@/enums/validation.enum';
import { useAppStore } from '@/stores/app.store';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type {
  ProductModel,
  ProductProjectRequest
} from '@/types/project-product/project-product-model.type';
import type { ProductProjectResponse } from '@/types/project-product/project-product-response.type';
import { throwContentError } from '@/utils/api-error.util';
import { redirectTo, showConfirmLeaveDialog } from '@/utils/common.util';
import { getProductDetailTypeLabel } from '@/utils/product.util';
import { generateErrorMsg } from '@/utils/validation.util';

const app = useApp();
const { t } = useI18n();

const DEFAULT_PRODUCT_NUMBER_IN_BULK = 2;

const route = useRoute();
const { projectName } = route.query;

const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);

const { addProductToSelectedProductListHistory, addProductsToSelectedProductListHistory } =
  useAppStore();

const { handleSubmit } = useForm<{
  productOriginal: ProductModel;
  numberOfProducts: number;
}>({
  initialValues: {
    productOriginal: {
      productName: '',
      productType: ''
    },
    numberOfProducts: DEFAULT_PRODUCT_NUMBER_IN_BULK
  }
});

const items: FormOption[] = PRODUCT_TYPES;

const { isLoading } = storeToRefs(useAppStore());
const forceDisable = ref<boolean>(false);
const isCheckLeave = ref<boolean>(true);
const isDisabled = computed(() => isLoading.value || forceDisable.value);

const numberOfProducts = useFieldValue<number>('numberOfProducts');
const setNumberOfProducts = useSetFieldValue('numberOfProducts');
const productOriginal = useFieldValue<ProductModel>('productOriginal');
const setProductNameError = useSetFieldError('productOriginal.productName');
const registerBulk = ref<boolean>(false);

const handleChangeBulk = (value: boolean) => {
  if (value) {
    setNumberOfProducts(DEFAULT_PRODUCT_NUMBER_IN_BULK);
  }
};

const productBulkRules = computed(() => {
  if (!registerBulk.value) {
    return {};
  }

  return {
    required: {
      value: true,
      message: t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.prod_num_input_val_msg')
    },
    number: t('studio.common.def_key.number_only_y'),
    gte: {
      value: 2,
      message: t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.prod_num_input_val_msg2')
    },
    lte: {
      value: 50,
      message: t('studio.prj_prod_mngmt.prj_prod_reg.prod_mass_reg.prod_num_input_val_msg2')
    }
  };
});

const registerSingleProduct = async () => {
  const projectId = route.params.projectId as string;
  const productName = productOriginal.value.productName.trim();
  const productType = productOriginal.value.productType;
  const result = await createProductInProjectApi({
    projectId,
    productName,
    productType
  });
  if (result) {
    isCheckLeave.value = false;
    const productDetailType = PRODUCT_DETAIL_TYPE.BASIC;
    addProductToSelectedProductListHistory({
      groupId: selectedGroupId.value,
      projectId,
      productNo: result.productNo,
      productName,
      productType,
      productDetailType,
      productDetailLabel: getProductDetailTypeLabel(productType, Confirmation.NO, productDetailType)
    });
    await redirectTo(PROJECT_MNG_PAGE_URL);
  }
};

const registerBulkProducts = async () => {
  const dialogResult = await showDialog<ProductModel[]>({
    component: shallowRef(AddProductMassDialog),
    props: {
      numberOfProducts: numberOfProducts.value,
      productOriginal: productOriginal.value,
      groupId: selectedGroupId.value,
      projectName: projectName as string
    },
    severity: 'info'
  });
  if (dialogResult.length === 0) {
    return;
  }

  const resultCreateBulk = await createProductBulkInProjectApi({
    projectId: route.params.projectId as string,
    products: dialogResult.map((product: ProductProjectRequest) => ({
      productName: product.productName.trim(),
      productType: product.productType
    }))
  });

  if (resultCreateBulk && Array.isArray(resultCreateBulk.products)) {
    isCheckLeave.value = false;
    const productType = productOriginal.value.productType;
    const productDetailType = PRODUCT_DETAIL_TYPE.BASIC;
    const selectedProducts = resultCreateBulk.products.map((product: ProductProjectResponse) => ({
      groupId: selectedGroupId.value,
      projectId: route.params.projectId as string,
      productNo: Number(product.productNo),
      productName: product.productName,
      productType,
      productDetailType,
      productDetailLabel: getProductDetailTypeLabel(productType, Confirmation.NO, productDetailType)
    }));
    addProductsToSelectedProductListHistory(selectedGroupId.value, selectedProducts);
    await redirectTo(PROJECT_MNG_PAGE_URL);
  }
};

const onSubmit = handleSubmit(async () => {
  if (!(await app.checkMenuWritable())) {
    return;
  }
  forceDisable.value = true;
  try {
    const productNameCheck = await checkProductInProjectNameApi({
      groupId: selectedGroupId.value,
      productName: productOriginal.value.productName.trim(),
      languageCd: 'ALL'
    });
    if (!productNameCheck?.success) {
      return;
    }

    if (!registerBulk.value) {
      const checkRegisterProductPossible = await checkRegisterProductInProjectBulkApi({
        projectId: route.params.projectId as string
      });
      if (!checkRegisterProductPossible?.success) {
        return;
      }
      await registerSingleProduct();
      return;
    }

    const checkRegisterProductPossible = await checkRegisterProductInProjectBulkApi({
      projectId: route.params.projectId as string,
      count: numberOfProducts.value
    });
    if (!checkRegisterProductPossible?.success) {
      return;
    }

    await registerBulkProducts();
    forceDisable.value = false;
  } catch (e: unknown) {
    forceDisable.value = false;
    const error = e as ErrorResponse;
    if (error.statusCode === STATUS_CODE.PRODUCT_NAME_DUPLICATED) {
      setProductNameError(
        generateErrorMsg(
          'studio.prj_prod_mngmt.prj_reg.prod_name_val_msg3',
          RuleNames.CHECK_DUPLICATE
        )
      );
      return;
    }

    if (error.statusCode === STATUS_CODE.PRODUCT_MAX_EXISTED) {
      const content = `${t(
        'studio.prj_prod_mngmt.post_prj_reg.prod_num_over1000_val_msg1'
      )}<br/>${t('studio.prj_prod_mngmt.post_prj_reg.prod_num_over1000_val_msg2')}`;

      await throwContentError(content);
    }
  }
});
onBeforeRouteLeave(async () => {
  if (isCheckLeave.value) {
    return showConfirmLeaveDialog();
  }
});
</script>
